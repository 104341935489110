<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    dark
    clipped
    width="360"
    class="sidebar"
    mobile-breakpoint="0"
    mini-variant-width="80"
    :mini-variant="mini"
    v-if="user && !user.is_agency && !user.is_ambassador"
  >
    <v-row class="fill-height" no-gutters>
      <v-navigation-drawer
        permanent
        mini-variant
        width="360"
        mini-variant-width="80"
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img
              src="https://ecupplus.blob.core.windows.net/logo/LOGO_B_ec-up_mark.png"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-divider></v-divider>
        <v-list nav dense>
          <v-list-item
            v-if="user.completed_first_update_items"
            class="px-0 py-0 my-2"
            :class="isSelectDashboard ? 'sid3bar_activated' : ''"
            @click="resetMenu"
          >
            <router-link :to="{ name: 'Dashboard' }">
              <v-list>
                <v-list-item style="min-height: 0px">
                  <v-list-item-icon class="my-0">
                    <v-icon>mdi-view-dashboard-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-subheader
                  class="justify-center text-center"
                  style="height: auto"
                >
                  <span>ダッシュ<br />ボード</span>
                </v-subheader>
              </v-list>
            </router-link>
          </v-list-item>

          <v-list-item
            v-else
            class="px-0 py-0 my-2"
            :class="isSelectInstallationStatus ? 'sid3bar_activated' : ''"
          >
            <router-link :to="{ name: 'InstallationStatus' }">
              <v-list-item style="min-height: 0px">
                <v-list-item-icon class="my-0">
                  <v-icon>mdi-monitor-dashboard</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-subheader
                class="justify-center text-center"
                style="height: auto"
              >
                <span>EC-UP<br />初期設定<br />状況</span>
              </v-subheader>
            </router-link>
          </v-list-item>

          <template v-if="user.completed_first_update_items">
            <v-list-item
              class="px-0 py-0 my-2"
              :class="
                isSelectItemMenu ? `sid3bar_activated ${selectMenuKey}` : ''
              "
              @click="selectMenu(MENU_ITEM)"
            >
              <v-list>
                <v-list-item style="min-height: 0px">
                  <v-list-item-icon class="my-0">
                    <v-icon>mdi-gift-open-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-subheader
                  class="justify-center text-center"
                  style="height: auto"
                >
                  <span>商品<br />ページ</span>
                </v-subheader>
              </v-list>
            </v-list-item>

            <v-list-item
              class="px-0 py-0 my-2"
              :class="
                isSelectTopMenu ? `sid3bar_activated ${selectMenuKey}` : ''
              "
              @click="selectMenu(MENU_SHOP)"
            >
              <v-list>
                <v-list-item style="min-height: 0px">
                  <v-list-item-icon class="my-0">
                    <v-icon>mdi-storefront-outline</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-subheader
                  class="justify-center text-center"
                  style="height: auto"
                >
                  <span>トップ<br />ページ</span>
                </v-subheader>
              </v-list>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <v-list
        v-if="!mini && selectMenuKey === MENU_ITEM"
        light
        class="item-menu-list grow py-0 px-2"
      >
        <div style="position: absolute; right: 8px; top: 8px; z-index: 1">
          <v-btn
            icon
            tile
            small
            class="grey lighten-5"
            style="border-radius: 8px"
            @click="toMini"
          >
            <v-icon color="#6D6D6D">mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>

        <v-list-item two-line class="justify-center py-3 pr-10">
          <v-icon size="48" color="#00736D" class="mr-3"
            >mdi-gift-open-outline</v-icon
          >
          <div class="text-h6">商品ページ</div>
        </v-list-item>

        <div class="px-2 mb-3">
          <v-divider class="dashed-divider" />
        </div>

        <v-list-item link :to="{ name: 'ItemForSmartPhone' }">
          <v-icon class="mr-2">mdi-cellphone-text</v-icon>
          <div class="text-body-2">スマホ説明文一覧</div>
          <v-chip
            v-if="isFullAutoOnForSmartPhone"
            small
            color="blue lighten-4"
            text-color="info"
            class="ml-2 font-weight-bold"
            >ON</v-chip
          >
          <v-chip
            v-else
            small
            text-color="grey darken-1"
            class="ml-2 font-weight-bold"
            >OFF</v-chip
          >
        </v-list-item>

        <v-list-item link :to="{ name: 'ItemBySalesMethod' }">
          <v-icon class="mr-2" style="transform: rotate(180deg)"
            >mdi-comment-text-outline</v-icon
          >
          <div class="text-body-2">PC販売説明文一覧</div>
          <v-chip
            v-if="isFullAutoOnBySalesMethod"
            small
            color="blue lighten-4"
            text-color="info"
            class="ml-2 font-weight-bold"
            >ON</v-chip
          >
          <v-chip
            v-else
            small
            text-color="grey darken-1"
            class="ml-2 font-weight-bold"
            >OFF</v-chip
          >
        </v-list-item>

        <v-list-item link :to="{ name: 'ItemForPC' }">
          <v-icon class="mr-2">mdi-comment-text-outline</v-icon>
          <div class="text-body-2">PC商品説明文一覧</div>
          <v-chip
            v-if="isFullAutoOnForPC"
            small
            color="blue lighten-4"
            text-color="info"
            class="ml-2 font-weight-bold"
            >ON</v-chip
          >
          <v-chip
            v-else
            small
            text-color="grey darken-1"
            class="ml-2 font-weight-bold"
            >OFF</v-chip
          >
        </v-list-item>

        <v-subheader class="grey--text text--darken-4"> 設定 </v-subheader>

        <v-list-item link :to="{ name: 'ConfigFullAuto' }">
          <v-icon class="mr-2">mdi-auto-mode</v-icon>
          <div class="text-body-2">おまかせ設定</div>
        </v-list-item>

        <v-list-item link :to="{ name: 'ConfigDisplaySettings' }">
          <v-icon class="mr-2">mdi-palette-outline</v-icon>
          <div class="text-body-2">表示・デザイン</div>
        </v-list-item>
      </v-list>

      <v-list
        v-else-if="!mini && selectMenuKey === MENU_SHOP"
        light
        class="shop-menu-list grow py-0 px-2"
      >
        <div style="position: absolute; right: 8px; top: 8px; z-index: 1">
          <v-btn
            icon
            tile
            small
            class="grey lighten-5"
            style="border-radius: 8px"
            @click="toMini"
          >
            <v-icon color="#6D6D6D">mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>

        <v-list-item two-line class="justify-center py-3 pr-10">
          <v-icon size="48" color="#AC6A00" class="mr-3"
            >mdi-storefront-outline</v-icon
          >
          <div class="text-h6">トップページ</div>
        </v-list-item>

        <div class="px-2 mb-3">
          <v-divider class="dashed-divider" />
        </div>

        <v-list-item link exact :to="{ name: 'ShopPageLayouts' }">
          <v-icon class="mr-2">mdi-format-list-text</v-icon>
          <div class="text-body-2">ページ一覧</div>
        </v-list-item>

        <v-subheader class="grey--text text--darken-4"> 設定 </v-subheader>

        <v-list-item link exact :to="{ name: 'ShopPageDisplaySettings' }">
          <v-icon class="mr-2">mdi-palette-outline</v-icon>
          <div class="text-body-2">表示・デザイン</div>
        </v-list-item>
      </v-list>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "Sidebar",
  data: () => ({
    drawer: true,
    mini: true,
    MENU_ITEM: "item",
    MENU_SHOP: "shop",
    selectMenuKey: "",
  }),
  created() {
    if (
      this.$route.path.includes("/items") ||
      this.$route.name === "ConfigFullAuto"
    ) {
      this.selectMenuKey = this.MENU_ITEM;
    } else if (this.$route.name === "ShopPageLayouts") {
      this.selectMenuKey = this.MENU_SHOP;
    }
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isFullAutoOnForSmartPhone() {
      return this.user.is_full_auto_on || false;
    },
    isFullAutoOnForPC() {
      return this.user.is_full_auto_on_ForPC || false;
    },
    isFullAutoOnBySalesMethod() {
      return this.user.is_full_auto_on_BySalesMethod || false;
    },
    isSelectDashboard() {
      return !this.selectMenuKey && this.$route.name === "Dashboard";
    },
    isSelectInstallationStatus() {
      return !this.selectMenuKey && this.$route.name === "InstallationStatus";
    },
    isSelectItemMenu() {
      return this.selectMenuKey === this.MENU_ITEM;
    },
    isSelectTopMenu() {
      return this.selectMenuKey === this.MENU_SHOP;
    },
  },
  methods: {
    toMini() {
      this.mini = true;
    },
    selectMenu(key) {
      this.selectMenuKey = key;
      this.mini = false;
    },
    resetMenu() {
      this.selectMenuKey = "";
      this.mini = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.sid3bar_activated {
  background: linear-gradient(to bottom right, #778e19, #21889f) !important;
  color: white;

  &.theme--dark.v-list-item--active:before {
    opacity: 0 !important;
  }

  &.item {
    background: #00736d !important;
  }

  &.shop {
    background: #ac6a00 !important;
  }
}
.sidebar {
  .v-chip {
    cursor: pointer;
    height: 20px;
  }
  .theme--light.v-chip:hover:before {
    opacity: 0;
  }

  .item-menu-list {
    background: linear-gradient(to bottom right, #d4ecea, #ecf4d9) !important;
  }

  .shop-menu-list {
    background: linear-gradient(to bottom right, #feecd2, #ecf4d9) !important;
  }

  div > .dashed-divider {
    border-style: dashed;
    border-color: gray;
  }
}
</style>
