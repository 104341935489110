<template>
  <div class="shop_page_layout">
    <v-row>
      <v-col class="body-2">
        【初めての方】トップページにシステムをつける方法はこちら⇒
        <a @click="showHowTo()">
          「トップページにシステムを表示する・更新する・取り外す方法」
        </a>
      </v-col>
    </v-row>

    <div v-if="isShowTable" class="my-6 pa-8 white" style="border-radius: 1rem">
      <v-row>
        <v-col cols="auto">
          <v-btn
            class="font-weight-bold text-body-1"
            color="#92B126"
            elevation="4"
            :disabled="syncLayoutsJobs.length > 0"
            rounded
            large
            @click="syncLayouts"
          >
            <v-icon class="white--text" left>mdi-reload</v-icon>
            <span class="white--text"> 最新状態に更新する </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters id="tableArea2" class="mt-3" style="position: relative">
        <v-col cols="9">
          <v-simple-table
            class="scroll_item"
            style="border: solid 0px #f7f7f7 !important"
          >
            <item-table-head
              :headers="headers"
              :order="order"
              :direction="direction"
              @sort="sortItems($event)"
            />

            <tbody>
              <tr
                v-for="item in shopPageLayouts"
                :key="item.id"
                height="80px"
                class="white"
                :class="{
                  'red lighten-5':
                    item.shop_top_job_errors && item.shop_top_job_errors.length,
                }"
              >
                <td class="justify-start text-body-1" width="45%">
                  <v-badge
                    :value="item.shop_top_job_errors.length"
                    color="error"
                    icon="mdi-exclamation-thick"
                  >
                    <v-tooltip
                      bottom
                      :disabled="item.shop_top_job_errors.length === 0"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                          {{ item.title }}
                        </span>
                      </template>
                      <span
                        v-for="error in uniqErrors(item.shop_top_job_errors)"
                      >
                        {{ error.message }}<br />
                      </span>
                    </v-tooltip>
                  </v-badge>
                </td>

                <td class="px-0" width="15%">
                  <span class="text-body-1">{{
                    item.remaining_widget_count
                  }}</span>
                </td>
                <td class="px-0" width="40%">
                  {{ time_ymdt_format(item.updateTime) }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>

        <v-col cols="3" style="position: relative">
          <v-simple-table
            id="tableSlider2"
            class="scroll_item"
            style="position: relative; border: solid 0px #f7f7f7 !important"
          >
            <thead>
              <tr height="90px" class="bg-gray">
                <th
                  class="bg-gray text-center item_table_nallow_th px-2"
                  v-for="shopTopSystem in shopTopSystems"
                  :key="shopTopSystem.id"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><img
                          class="d-inline-block mb-1"
                          :src="'/images/items/' + shopTopSystem.code + '.png'"
                          alt=""
                          width="48px"
                      /></span>
                    </template>
                    {{ shopTopSystem.name }}システムの稼働状況
                  </v-tooltip>
                  <template v-if="shopTopSystem.name_short">{{
                    shopTopSystem.name_short
                  }}</template>
                  <template v-else>{{ shopTopSystem.name }}</template>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="shopPageLayout in shopPageLayouts"
                :key="shopPageLayout.id"
                height="80px"
              >
                <td
                  v-for="shopTopSystem in shopTopSystems"
                  :key="shopTopSystem.id"
                  class="text-center ecup_system_td"
                  style="
                    background-color: #feecd2;
                    border-left: 1px solid rgba(0, 0, 0, 0.12);
                  "
                >
                  <v-tooltip
                    bottom
                    v-if="isUnfinishedJob(shopPageLayout.id, shopTopSystem.id)"
                    content-class="caption"
                  >
                    <template v-slot:activator="{ on, attrs1 }">
                      <v-icon
                        large
                        mdi
                        v-bind="attrs1"
                        v-on="on"
                        color="primary"
                        class="item_sync_icon"
                        v-show="
                          isUnfinishedJob(shopPageLayout.id, shopTopSystem.id)
                        "
                        >mdi-autorenew</v-icon
                      >
                    </template>
                    <span class="tooltip_icon_description">設定中</span>
                  </v-tooltip>
                  <template v-else>
                    <v-tooltip
                      bottom
                      v-show="
                        shopPageLayout['widget_count_' + shopTopSystem.code] > 0
                      "
                      content-class="caption"
                    >
                      <template v-slot:activator="{ on, attrs2 }">
                        <v-icon
                          large
                          mdi
                          v-bind="attrs2"
                          v-on="on"
                          color="#AC6A00"
                          v-show="
                            shopPageLayout[
                              'widget_count_' + shopTopSystem.code
                            ] > 0
                          "
                          @click="
                            openSystemModal(shopPageLayout, shopTopSystem)
                          "
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </template>
                      <span class="tooltip_icon_description"
                        >稼働中<br />クリックでシステムを更新/取り外す</span
                      >
                    </v-tooltip>
                    <v-tooltip
                      bottom
                      v-show="
                        shopPageLayout['widget_count_' + shopTopSystem.code] ===
                        0
                      "
                    >
                      <template v-slot:activator="{ on, attrs3 }">
                        <v-icon
                          large
                          mdi
                          v-bind="attrs3"
                          v-on="on"
                          v-show="
                            shopPageLayout[
                              'widget_count_' + shopTopSystem.code
                            ] === 0
                          "
                          color="#A1A1A1"
                          @click="
                            openSystemModal(shopPageLayout, shopTopSystem)
                          "
                          >mdi-check-circle-outline</v-icon
                        >
                      </template>
                      <span class="tooltip_icon_description"
                        >未稼働<br />クリックでシステムを表示する</span
                      >
                    </v-tooltip>
                  </template>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div class="arrows">
            <span
              class="arrows_btn arrows_btn-left"
              v-show="tableColsCount > 0"
              @click="tableSlide('prev')"
              ><v-icon dark>mdi-chevron-left</v-icon>
            </span>

            <span
              class="arrows_btn arrows_btn-right"
              v-show="tableColsMax - tableCols > tableColsCount"
              @click="tableSlide('next')"
              ><v-icon dark>mdi-chevron-right</v-icon>
            </span>
          </div>
        </v-col>

        <v-overlay v-if="isLoadingTable" absolute opacity="1" color="#fff">
          <v-progress-circular
            indeterminate
            color="primary"
            size="32"
          ></v-progress-circular>
        </v-overlay>
        <v-overlay v-else-if="isFetchingItem" absolute opacity="0.25">
          <v-progress-circular
            indeterminate
            color="primary"
            size="32"
          ></v-progress-circular>
        </v-overlay>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <v-btn
            x-large
            outlined
            color="#bf0000"
            class="rms-setting-btn"
            target="_blank"
            :href="`https://store.rms.rakuten.co.jp/rms-shop-page-management/shops/${user.shop_id}/shop-top/layouts`"
            >反映設定（RMSより反映してください）</v-btn
          >
        </v-col>
      </v-row>
    </div>

    <v-row justify="end">
      <v-col cols="auto">
        <v-icon right>mdi-email</v-icon>
        <a
          :href="config('HREF_FEEDBACK')"
          target="_blank"
          style="text-decoration: underline"
        >
          お問い合わせ
        </a>
      </v-col>
    </v-row>

    <system-edit-modal
      :is-render="isRenderSystemModal"
      :shop-page-layout="shopPageLayout"
      :shop-top-system="shopTopSystem"
      @close="closeSystemModal"
      @save="saveShopPageLayout"
    />
    <floating-notification-alert
      :open="isShowFinishedMessage"
      @close="isShowFinishedMessage = false"
    >
      <v-icon color="success">mdi-checkbox-marked</v-icon>
      <span color="success">システム設定を受け付けました。</span>
    </floating-notification-alert>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.get["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["X-Requested-With"] = "";
axios.defaults.headers.common["X-CSRF-TOKEN"] = "";
axios.defaults.headers.common["Access-Control-Request-Method"] = "";

import ItemTableHead from "./ItemTableHead.vue";
import SystemEditModal from "./SystemEditModal.vue";
import FloatingNotificationAlert from "../../components/alert/FloatingNotificationAlert.vue";
import ToolUtilsMixin from "../../../packs/mixins/tool_utils";
import timeUtilsMixin from "../../../packs/mixins/time_utils";
import config from "../../../const";

export default {
  components: {
    ItemTableHead,
    SystemEditModal,
    FloatingNotificationAlert,
  },

  mixins: [ToolUtilsMixin, timeUtilsMixin],

  async created() {
    this.searchItems();
  },

  props: {
    user: { type: Object },
    name: { type: String },
    headers: { type: Object },
  },

  data() {
    return {
      order: "updateTime",
      direction: "desc",
      shopPageLayouts: [],
      shopTopSystems: [],
      shopPageLayout: null,
      shopTopSystem: null,
      unfinishedJobs: [],
      syncLayoutsJobs: [],
      tableWidth: "",
      tableCols: 2,
      tableColWidth: "",
      tableColsCount: 0,
      timer: 0,
      refreshTimer: 0,
      syncLayoutsTimer: 0,
      intervalSeconds: 10,
      isShowTable: false,
      isLoadingTable: true,
      isFetchingItem: false,
      isRenderSystemModal: false,
      isShowFinishedMessage: false,
    };
  },

  computed: {
    isUnfinishedJob() {
      return (shopPageLayoutId, shopTopSystemId) => {
        return this.unfinishedJobs.find(
          (job) =>
            job.shop_page_layout_id === shopPageLayoutId &&
            job.shop_top_system_id === shopTopSystemId
        );
      };
    },
    tableColsMax() {
      return this.shopTopSystems.length;
    },
  },

  watch: {},

  methods: {
    async searchItems() {
      this.isShowTable = true;
      if (!this.unfinishedJobs.length) {
        this.fetchUnfinishedJobs();
      }

      if (!this.syncLayoutsJobs.length) {
        this.fetchSyncLayoutsJobs();
      }

      await this.fetchItems();

      this.tableCreate();
      window.addEventListener("scroll", this.handleScroll);
      window.addEventListener("resize", this.handleResize);
      this.isLoadingTable = false;
    },

    async fetchUnfinishedJobs() {
      if (this.refreshTimer > 0) {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = 0;
      }

      const beforUnfinishedJobs = [...this.unfinishedJobs];

      return axios
        .get("/api/v1/shop_top_jobs/unfinished_jobs")
        .then((response) => {
          this.unfinishedJobs = response.data.unfinished_jobs;

          // 未完了のジョブがあれば未完了ジョブ取得を定期的に実行する
          if (this.unfinishedJobs.length) {
            this.refreshTimer = setTimeout(() => {
              this.fetchUnfinishedJobs();
            }, this.intervalSeconds * 1000);
          }

          const finishedShopPageLayoutIds = [];
          beforUnfinishedJobs.forEach((job) => {
            // 未完了だったジョブが今回も未完了のままか
            const index = this.unfinishedJobs.findIndex((after) => {
              return (
                job.shop_page_layout_id === after.shop_page_layout_id &&
                job.shop_top_system_id === after.shop_top_system_id
              );
            });

            // 完了していた かつ 完了リストに未追加
            if (
              index < 0 &&
              !finishedShopPageLayoutIds.includes(job.shop_page_layout_id)
            ) {
              finishedShopPageLayoutIds.push(job.shop_page_layout_id);
            }
          });

          // 完了したものがあれば
          if (finishedShopPageLayoutIds.length) {
            this.fetchItems(finishedShopPageLayoutIds);
          }
        });
    },

    async fetchItems(finishedIds = []) {
      this.isFetchingItem = true;

      const params = {
        order: this.order,
        direction: this.direction,
        finished_ids: finishedIds,
      };

      await axios
        .get("/api/v1/shop_page_layouts/", { params })
        .then((response) => {
          if (!finishedIds.length) {
            this.shopPageLayouts = response.data.shop_page_layouts;
          } else {
            response.data.shop_page_layouts.forEach((item) => {
              const index = this.shopPageLayouts.findIndex((target) => {
                return item.id === target.id;
              });

              if (index >= 0) {
                this.shopPageLayouts[index] = item;
              }
            });
          }

          this.shopTopSystems = response.data.shop_top_systems;
        });

      this.tableColsCount = 0;
      this.tableCreate();
      this.tableSlide();

      this.isFetchingItem = false;
    },

    async reload() {
      this.$emit("fetchUser");
      await this.fetchUnfinishedJobs();
      await this.fetchItems();
    },

    sortItems(key) {
      if (key == this.order) {
        this.direction = this.direction == "asc" ? "desc" : "asc";
      } else {
        this.order = key;
        this.direction = "asc";
      }

      this.fetchItems();
    },

    openSystemModal(shopPageLayout, system) {
      this.shopPageLayout = shopPageLayout;
      this.shopTopSystem = system;
      this.isRenderSystemModal = true;
    },

    closeSystemModal() {
      this.shopPageLayout = null;
      this.shopTopSystem = null;
      this.isRenderSystemModal = false;
    },

    saveShopPageLayout() {
      this.isRenderSystemModal = false;
      this.reload();
      this.isShowFinishedMessage = true;
    },

    tableCreate() {
      if (!document.getElementById("tableSlider2")) return;

      this.tableWidth = document.getElementById("tableSlider2").clientWidth;
      this.tableColWidth = Math.ceil(this.tableWidth / this.tableCols);

      Array.from(
        document.getElementById("tableSlider2").getElementsByTagName("th"),
        (element, index) => {
          element.style.width = this.tableColWidth + "px";
        }
      );
      Array.from(
        document.getElementById("tableSlider2").getElementsByTagName("td"),
        (element, index) => {
          element.style.width = this.tableColWidth + "px";
        }
      );

      // theadにwidth付与
      Array.from(
        document.getElementById("tableArea2").getElementsByTagName("thead"),
        (element, index) => {
          element.style.width = "100%";
        }
      );
    },

    tableSlide(action) {
      if (action === "next") {
        // next
        if (this.tableColsMax - this.tableCols > this.tableColsCount) {
          this.tableColsCount++;
        }
      } else if (action === "prev") {
        // prev
        if (this.tableColsCount > 0) {
          this.tableColsCount--;
        }
      }

      if (!document.getElementById("tableSlider2")) return;

      Array.from(
        document.getElementById("tableSlider2").getElementsByTagName("tr"),
        (element) => {
          element.style.transform =
            "translate(-" +
            this.tableColWidth * this.tableColsCount +
            "px, 0px)";
        }
      );
    },

    handleResize() {
      if (this.timer > 0) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(() => {
        this.tableCreate();
        this.tableSlide();
        this.handleScroll("resize");
      }, 200);
    },

    handleScroll(e) {
      var top = document
        .getElementById("tableSlider2")
        .getBoundingClientRect().top;
      var table = document.getElementById("tableArea2");

      if (
        (top < 1 && !table.classList.contains("fixed")) ||
        (top < 1 && e === "resize")
      ) {
        table.classList.add("fixed");

        var offset =
          window.innerWidth -
          document.getElementById("tableSlider2").getBoundingClientRect().right;
        document.querySelector(
          "#tableArea2 .arrows_btn-right"
        ).style.transform = "translate(-" + offset + "px, 0px)";
      } else if (top > 1 && table.classList.contains("fixed")) {
        table.classList.remove("fixed");
        document.querySelector(
          "#tableArea2 .arrows_btn-right"
        ).style.transform = "translate(0px, 0px)";
      }
    },

    config(value) {
      return config[value];
    },

    syncLayouts() {
      axios
        .post("/api/v1/shop_top_jobs", { operation: "sync_layouts" })
        .then(() => {
          this.fetchSyncLayoutsJobs();
        });
    },

    async fetchSyncLayoutsJobs() {
      if (this.syncLayoutsTimer > 0) {
        clearTimeout(this.syncLayoutsTimer);
        this.syncLayoutsTimer = 0;
      }

      const beforeSyncLayoutsJobCount = this.syncLayoutsJobs.length;

      return axios
        .get("/api/v1/shop_top_jobs/sync_layouts_jobs")
        .then((response) => {
          this.syncLayoutsJobs = response.data.sync_layouts_jobs;

          // 未完了のジョブがあれば未完了ジョブ取得を定期的に実行する
          if (this.syncLayoutsJobs.length) {
            this.syncLayoutsTimer = setTimeout(() => {
              this.fetchSyncLayoutsJobs();
            }, this.intervalSeconds * 1000);
          } else if (beforeSyncLayoutsJobCount > 0) {
            // 同期中のジョブがなくなったら
            this.fetchItems();
          }
        });
    },

    showHowTo() {
      window.open(
        this.$router.resolve({ name: "HowToShopTop" }).href,
        "_blank",
        "width=775,height=485"
      );
    },

    uniqErrors(errors) {
      // Setを使ってユニークなエラーメッセージを抽出
      const uniqMessages = new Set(errors.map((error) => error.message));
      console.log(uniqMessages);
      return Array.from(uniqMessages).map((message) => ({
        message: message,
      }));
    },
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #f7f7f7 !important;
}

p {
  font-size: 2em;
  text-align: center;
}
.tooltip_icon_description {
  font-size: 0.8rem;
  text-align: center;
  display: inline-block;
  width: 100%;
}

.item_sync_icon {
  animation-name: spin;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(3360deg);
  }
}

td.ecup_system_td.full_auto_on {
  pointer-events: none;
  opacity: 0.3;
}

.scroll_item table tr:not(:last-of-type) td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.scroll_item table tr td:first-of-type {
  border-left: thin solid rgba(0, 0, 0, 0.12);
}
#tableArea2 {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}
.rms-setting-btn {
  border-radius: 16px;
  border-width: initial;
  font-weight: 500;
}
</style>
<style>
#tableArea2 .scroll_item table {
  position: relative;
  padding-top: 90px;
}

#tableArea2 .scroll_item table thead {
  position: absolute;
  top: 0;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
  z-index: 1;
}

#tableArea2 .scroll_item table tr {
  position: relative;
  display: flex;
  transition: all 0.1s ease-out;
}

#tableArea2 .scroll_item table th {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-bottom: none;
}
#tableArea2 .scroll_item table th.itemName {
  width: 42%;
}
#tableArea2 .scroll_item table th.remainingBytes,
#tableArea2 .scroll_item table th.remainingImages,
#tableArea2 .scroll_item table th.omakase {
  width: 24%;
}
#tableArea2 .scroll_item table td {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 0;
}

#tableSlider2 .v-data-table__wrapper {
  position: relative;
  overflow-x: hidden;
}
#tableSlider2 .v-data-table__wrapper::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1px;
  height: 100%;
  background: rgba(0, 0, 0, 0.12);
}
#tableSlider2 table {
  table-layout: fixed;
  position: relative;
  left: 0;
  width: auto;
}
#tableSlider2 table thead tr {
  flex-flow: column wrap;
  align-content: flex-start;
}
#tableSlider2 table th {
  flex-direction: column;
}
#tableSlider2 table td:last-child {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.arrows {
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% + 30px);
  background: red;
  margin: 0 -15px;
}
.arrows_btn {
  position: absolute;
  top: 28px;
  padding: 4px;
  background: #999;
  border-radius: 50%;
  opacity: 0.8;
  z-index: 2;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out, background 0.2s ease-in-out;
}
.arrows_btn:hover {
  background: #2a8cc7;
  opacity: 1;
}
.arrows_btn-left {
  left: 0;
}
.arrows_btn-right {
  right: 0;
}

#tableArea2.fixed .scroll_item table thead {
  position: fixed;
}
#tableArea2.fixed .arrows_btn {
  position: fixed;
}
#tableArea2.fixed .arrows_btn-left {
  left: auto;
}
#tableArea2.fixed .arrows_btn-right {
  right: -15px;
}
</style>
